import service from "@/common/register.js";

/**
 * 创建用户，用于注册
 * @param {*} param phone_number
 * first_namet3333

 */
// //  自激活注册
export const linkreg = (param) => service.post("/auth/users/linkreg/", param);

// //  自激活注册效验
export const linkchk = (param) => service.post("/auth/users/linkreg/", param);
