/**
 * 检查证电子邮件是否合法
 */
 function checkEmail(userEmail){
    if (userEmail == "" || userEmail == null) {
      return {data: false, msg: '请输入邮箱'};
    }
    var mailreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    if (!mailreg.test(userEmail)) {
      return {data: false, msg: '电子邮箱格式不正确'};
    }
    return {data: true, msg: ''};
  }

  function checkIphone(mobel){
    if (mobel == "" || mobel == null) {
      return {data: false, msg: '请输入正确的11位手机号码'};
    }
    var mailreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!mailreg.test(mobel)) {
      return {data: false, msg: '请输入正确的11位手机号码'};
    }
    return {data: true, msg: ''};
  }
  export default {
    checkEmail,
    checkIphone,
  }