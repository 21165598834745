import axios from "axios";
import { Message } from "element-ui";

import store from "@/store";
const pass_token = [
  "risk/five-abilities/",
  "risk/formated-fs/",
  "risk/four-abilities/",
  "/report/json/generic/",
  "risk/five-abilities/",
  "/auth/token/create",
  "/financing-calc/simple/",
  "/auth/users/",
  "/auth/sms/register/",
];
// create an axios instance

const service = axios.create({
  baseURL: window.BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (!pass_token.includes(config.url)) {
      config.headers = {
        ...config.headers,
      };
    }

    return config;
  },
  (error) => {
    // do something with request error

    return Promise.reject(error);
  }
);

let timer = null;

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 200, it is judged as an error.

    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      return Promise.reject(res);
    }
  },
  (error) => {
    const { response, request } = error;
    if (
      request.responseURL.includes("/auth/users/") ||
      request.responseURL.includes("/auth/sms/register/")
    ) {
      return Promise.reject(response.data);
    }
    if (response.status == 400) {
      const data = response.data;
      if (data["detail"]) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          Message({
            message: data["detail"],
            type: "error",
            duration: 5 * 1000,
          });
        }, 500);
        return false;
      }
      const dataArr = Object.values(data);
      const len = dataArr.length - 1;
      let text = "";
      dataArr.map((item, i) => {
        text += item[0] + (len == i ? "" : "、");
      });

      Message({
        message: text,
        type: "error",
        duration: 5 * 1000,
      });
    } else if (response.status == 401) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        Message({
          message: "登录已失效，请重新登录",
          type: "error",
          duration: 5 * 1000,
        });

        location.href = "/#/login";
      }, 500);
    } else {
      Message({
        message: "服务器忙",
        type: "error",
        duration: 5 * 1000,
      });
    }
  }
);

export default service;
